import axios from 'axios'

export default {
	loadItem (id, opt = {}) {
		return axios.get(`/api/roles/${id}`, opt)
	},
	loadList (params = {}) {
		return axios.get(`/api/roles`, { params })
	},
	saveItem (data) {
		return axios.post('/api/roles', data)
	},
	deleteItem (data) {
		return axios.delete(`/api/roles/${data.id}`)
	}
}
