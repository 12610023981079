import API from '@/api/users'
import { User, createUser } from '@/models/User'
import { createUserModuleAction } from '@/models/UserModuleAction'
import { createFormErrors } from '@/store/utils'
import { getField, updateField } from 'vuex-map-fields'

const state = () => {
	return {
		data: new User(),

		modulesActions: [],

		formErrors: createFormErrors(new User()),
		loading: false,
		saving: false,
		dirty: false
	}
}

const getters = {
	userActionsIds: (state) => state.modulesActions.map((v) => v.module_action_id),
	getField
}

const mutations = {
	setData (state, data = {}) {
		state.data = createUser(data)
		state.modulesActions = data.modulesActions && data.modulesActions.length > 0 ? data.modulesActions.map(x => createUserModuleAction(x)) : [ ]
		state.formErrors = createFormErrors(state.data)
	},
	addModulesAction ({ modulesActions }, p = {}) {
		for (let i = 0, l = modulesActions.length; i < l; i++) {
			if (modulesActions[i].module_action_id != p.module_action_id) continue
			modulesActions.splice(i, 1)
			break
		}

		modulesActions.push(createUserModuleAction(p))
	},
	setModuleAction (state, p = []) {
		state.modulesActions = p.map(x => createUserModuleAction(x))
	},
	synchroModulesMainActions (state, p = []) {
		// delete not set actions
		var actions = state.modulesActions.filter((v) => p.includes(v['module_action_id']))

		// add missing actions
		var allActions = actions.map((v) => v.module_action_id)

		p.forEach((v) => {
			if (allActions.includes(v)) return
			actions.push(createUserModuleAction({ module_action_id: v }))
		})

		state.modulesActions = actions
	},
	deleteModulesAction ({ modulesActions }, p) {
		modulesActions.splice(modulesActions.findIndex((v) => v === p), 1)
	},
	setErrors (state, p = {}) {
		state.formErrors = createFormErrors(state.data, p)
	},
	setLoading (state, b) {
		state.loading = b
	},
	setSaving (state, b) {
		state.saving = b
	},
	updateField
}

const actions = {
	loadItem (ctx, id) {
		ctx.commit('setLoading', true)
		return API.loadItem(id, { readModulesActions: true }).then(({ data }) => {
			ctx.commit('setData', data)

			return data
		}).catch((error) => {
			ctx.commit('core/snackbar/show', { text: error.message }, { root: true })

			throw error
		}).finally(() => {
			ctx.commit('setLoading', false)
		})
	},
	saveItem (ctx) {
		let savePayload = ctx.state.data
		savePayload['modulesActions'] = ctx.state.modulesActions

		ctx.commit('setSaving', true)

		return API.saveItem(savePayload).then(({ data }) => {
			return data
		}).catch((error) => {
			ctx.commit('core/snackbar/show', { text: error.message }, { root: true })
			if (error.formErrors) ctx.commit('setErrors', error.formErrors)

			throw error
		}).finally(() => {
			ctx.commit('setSaving', false)
		})
	}
}

export default function (store, storeName) {
	if (store.state[storeName]) return true

	store.registerModule(storeName, {
		namespaced: true,
		state,
		getters,
		actions,
		mutations
	})
}
