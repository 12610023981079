<template>
	<v-container grid-list-md>
		<v-layout wrap>
			<v-flex xs12><role-selector label="Používateľská rola" v-model="role_id" :disabled="rsDisabled"></role-selector></v-flex>
			<v-flex xs12>
				<v-list dense>
					<template v-for="module in modules">
						<v-list-group :value="module.active" :key="module.id" :prepend-icon="module.icon">
							<v-list-item slot="activator">
								<v-list-item-content>
									<v-list-item-title>{{ module.name }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item v-for="subItem in module.actions" :key="subItem.id" @click.stop="xorModules(subItem.id)">
								<v-list-item-action><v-checkbox v-model="userActionsIds" :value="subItem.id"></v-checkbox></v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>{{ subItem.name }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-group>
					</template>
				</v-list>
			</v-flex>
		</v-layout>
	</v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { xor } from 'lodash'

import rolesAPI from '@/api/roles'
import roleSelector from './RoleSelector'
import uuid from '@/mixins/uuid'

const entityStoreName = 'usersEntity'

export default {
	mixins: [ uuid ],
	data () {
		return {
			rsDisabled: false
		}
	},
	computed: {
		...mapFields(entityStoreName, [
			'data.role_id'
		]),
		...mapState('core/modules', { modulesList: 'data' }),
		...mapGetters(entityStoreName, [ 'userActionsIds' ]),
		modules () {
			return [ ...this.modulesList ].filter((v) => !!v.identifier).map((v) => {
				let r = { ...v }

				if (v.module_id) {
					let pm = this.$store.getters['core/modules/getOneBy']('id', v.module_id)
					r.name = pm.name + ' > ' + r.name
					r.icon = r.icon || pm.icon
				}

				return r
			})
		}
	},
	watch: {
		role_id (v) {
			this.rsDisabled = true

			// get modules
			rolesAPI.loadItem(v).then(({ data }) => {
				if (!data.modules_actions) return
				this.$store.commit(entityStoreName + '/setModuleAction', data.modules_actions)
			}).catch(({ response: { data } }) => {
				let msg = data.message || 'Internal error'
				this.$store.commit('core/snackbar/show', { text: msg }, { root: true })
			}).finally(() => {
				this.rsDisabled = false
			})
		}
	},
	methods: {
		xorModules (id) {
			let newActions = xor(this.userActionsIds, [ id ])
			this.$store.commit(entityStoreName + '/synchroModulesMainActions', newActions)
		}
	},
	components: {
		roleSelector
	}
}
</script>
