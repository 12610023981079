<template>
	<v-navigation-drawer v-model="show" absolute temporary right>
		<v-container>
			<v-row><v-col class="text-h6">Filter</v-col></v-row>
			<v-row><v-col><v-text-field :label="$t('Search...')" v-model="filter.query" autocomplete="off" hide-details filled clearable></v-text-field></v-col></v-row>
			<v-row><v-col><depot-selector v-model="filter.depot_id" :label="$t('Depot')" hide-details filled clearable></depot-selector></v-col></v-row>
			<v-row><v-col class="text-xs-right"><v-btn @click="resetFilter" small>RESET</v-btn></v-col></v-row>
		</v-container>
	</v-navigation-drawer>
</template>
<script>
import DepotSelector from '@/components/depots/DepotSelector'
import { debounce } from 'lodash'

export default {
	props: {
		store: String,
		value: Boolean
	},
	data () {
		return {
			filter: { }
		}
	},
	computed: {
		show: {
			get () {
				return this.value
			},
			set (v) {
				this.$emit('input', v)
			}
		},
		filterAPI () {
			let f = { ...this.filter }
			for (let i in f) {
				if (f[i] === null || f[i] === undefined || f[i] === '') delete f[i]
			}

			return f
		},
		filterActive () {
			return Object.keys(this.filterAPI).length > 0
		}
	},
	watch: {
		filterAPI: {
			handler: debounce(function (v) {
				this.$store.dispatch(this.store + '/setFilter', v)
			}, 700),
			deep: true
		},
		filterActive (v) {
			this.$store.commit('core/appToolbar/setFilterActive', v)
		}
	},
	methods: {
		resetFilter () {
			this.filter = { }
		}
	},
	components: {
		DepotSelector
	}
}
</script>
