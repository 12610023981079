<template>
<w-dialog v-model="showDialog" v-bind="dialogProps" :loading="loading" :processing="saving" @action="processActions">
	<v-toolbar dark color="primary" slot="title" flat>
		<v-toolbar-title>{{ name || 'Create new' | translate }}</v-toolbar-title>

		<template v-slot:extension>
			<v-tabs v-model="tab" grow>
				<v-tab key="basic">{{ 'Basic' | translate }}</v-tab>
				<v-tab key="rights">{{ 'Rights' | translate }}</v-tab>
				<v-tab v-if="id" key="mobileapp">{{ 'Mobile app' | translate }}</v-tab>
			</v-tabs>
		</template>
	</v-toolbar>

	<v-tabs-items v-model="tab">
		<v-tab-item key="basic">
			<v-container grid-list-md>
				<v-layout wrap>
					<v-flex xs6><v-text-field v-model="first_name" :label="$t('First name')" :error-messages="formErrorsMsg.first_name"></v-text-field></v-flex>
					<v-flex xs6><v-text-field v-model="last_name" :label="$t('Last name')" :error-messages="formErrorsMsg.last_name"></v-text-field></v-flex>
					<v-flex xs12><depot-selector v-model="depot_id" :label="$t('Depot')"></depot-selector></v-flex>
					<v-flex xs6><v-text-field v-model="username" :label="$t('Login')" :error-messages="formErrorsMsg.username"></v-text-field></v-flex>
					<v-flex xs6><v-text-field v-model="password" autocomplete="new-password" type="password" :label="$t('Password')" :error-messages="formErrorsMsg.password"></v-text-field></v-flex>
					<v-flex xs3><v-text-field v-model="mobile_pin" :label="$t('App Pin')" :error-messages="formErrorsMsg.mobile_pin"></v-text-field></v-flex>
					<v-flex xs3><v-text-field v-model="lori_id" :label="$t('Lori ID')" :error-messages="formErrorsMsg.lori_id"></v-text-field></v-flex>
					<v-flex xs12><v-checkbox v-model="status_id" label="aktívny" :false-value="200" :true-value="100"></v-checkbox></v-flex>
				</v-layout>
			</v-container>
		</v-tab-item>
		<v-tab-item key="rights"><Modules></Modules></v-tab-item>
		<v-tab-item v-if="id" key="mobileapp"><qr-code :id="id" :username="username"></qr-code></v-tab-item>
	</v-tabs-items>
</w-dialog>
</template>
<script>
import entityStoreInit from '@/store/users/entity'
import DepotSelector from '@/components/depots/DepotSelector'
import Modules from './Modules'
import QrCode from './QrCode'

import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

const entityStoreName = 'usersEntity'

export default {
	data () {
		return {
			dialogProps: {
				persistent: true,
				maxWidth: 500,
				buttons: [
					{ name: this.$i18n.translate('DlgCancel'), action: 'close' },
					{ name: this.$i18n.translate('DlgSave'), action: 'save', primary: true, color: 'primary', disabled: false }
				],
				buttonsAlign: 'right'
			},
			tab: null,
			showDialog: false
		}
	},
	computed: {
		...mapFields(entityStoreName, [
			'data.id', 'data.first_name', 'data.depot_id', 'data.last_name', 'data.username', 'data.password', 'data.mobile_pin', 'data.status_id', 'data.lori_id'
		]),
		...mapState(entityStoreName, [ 'loading', 'saving', 'formErrors' ]),
		name () {
			return this.first_name + ' ' + this.last_name
		},
		formErrorsMsg () {
			return Object.keys(this.formErrors).reduce((r, k) => {
				r[k] = this.$options.filters.translate(this.formErrors[k])
				return r
			}, {})
		}
	},
	methods: {
		processActions (a) {
			if (a.action === 'save') {
				this.$store.dispatch(entityStoreName + '/saveItem').then((data) => {
					this.$emit('data', data)
					this.close()
				}).catch(() => { })
			}

			if (a.action === 'close') {
				// check if data was changed
				// if yes, than bubble action to parent
				this.close()
			}
		},
		init (id) {
			this.tab = 0
			if (id) {
				this.$store.dispatch(entityStoreName + '/loadItem', id)
			} else {	// new item
				this.$store.commit(entityStoreName + '/setData')
			}

			this.$nextTick(() => {
				this.showDialog = true
			})
		},
		close () {
			this.showDialog = false
		}
	},
	beforeCreate () {
		entityStoreInit(this.$store, entityStoreName)
	},
	components: {
		Modules, DepotSelector, QrCode
	}
}
</script>
