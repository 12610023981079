<template>
	<v-container>
		<v-row>
			<v-col>
				Mobilnú aplikáciu pre <b>{{ username }}</b> inicializujete naskenovaním QR kódu
			</v-col>
		</v-row>
		<v-row>
			<v-col style="text-align:center">
				<img :src="qrimgsrc">
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {
	props: {
		id: null,
		username: String
	},
	computed: {
		qrimgsrc () {
			return `/api/users/${this.id}/qrcode`
		}
	}
}
</script>
