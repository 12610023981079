<template>
	<v-layout wrap>
		<v-flex xs12 sm12 md12 lg10 offset-lg1>
			<w-list :columns="columns" :items="items" :actions="actions" :loading="loading" @action="invokeAction"></w-list>
			<Dialog ref="Dialog" @data="DialogData"></Dialog>
		</v-flex>
		<v-btn color="red" fixed bottom right fab dark @click="createNewItem"><v-icon>mdi-plus</v-icon></v-btn>
		<v-flex xs12 class="text-center" v-show="totalPages && totalPages > 1">
			<v-pagination :length="totalPages" v-model="currentPage" :total-visible="6" class="mt-2"></v-pagination>
		</v-flex>
		<w-message-box v-bind="messageBox" @action="deleteListItem">Naozaj si prajete vymazať položku<br><b>{{ messageBox.text }}</b>&nbsp;?</w-message-box>
		<list-filter v-model="filterVisible" :store="$options.listStoreName"></list-filter>
	</v-layout>
</template>
<script>
import usersStoreInit from '@/store/users/list'
import uuid from '@/mixins/uuid'

import ListFilter from './ListFilter'
import Dialog from './Dialog'
import { mapState } from 'vuex'

export default {
	mixins: [ uuid ],
	data () {
		return {
			columns: [{
				headerText: this.$i18n.translate('Meno'),
				value: 'name'
			}, {
				headerText: 'Depo',
				value: 'depot_name'
			}, {
				headerText: 'Rola',
				value: 'role_name'
			}, {
				headerText: 'Login',
				value: 'username'
			}],
			actions: [
				{ name: 'delete', text: this.$i18n.translate('Delete'), icon: 'mdi-delete' }
			],
			messageBox: {
				value: false,
				item: null,
				buttons: [
					{ name: 'DlgNo' },
					{ name: 'DlgYes', primary: true, color: 'primary' }
				]
			}
		}
	},
	listStoreName: 'usersList-',
	computed: {
		...mapState({
			page (state) {
				return state[this.$options.listStoreName].page
			},
			data (state) {
				return state[this.$options.listStoreName].data
			},
			loading (state) {
				return state[this.$options.listStoreName].loading
			}
		}),
        filterVisible: {
            get () {
                return this.$store.state.core.appToolbar.filterVisible
            },
            set (v) {
                this.$store.commit('core/appToolbar/setFilterVisible', v)
            }
        },
		items () {
			return this.data.map((v) => {
				return { ...v, name: v.first_name + ' ' + v.last_name }
			})
		},
		totalPages () {
			return this.$store.getters[this.$options.listStoreName+'/totalPages']
		},
		currentPage: {
			get () {
				return this.page
			},
			set (v) {
				this.$store.dispatch(this.$options.listStoreName + '/setPage', v)
			}
		}
	},
	methods: {
		invokeAction ({ name, item }) {
			if (name === 'delete') {
				this.messageBox.text = item.plate_number
				this.messageBox.item = item
				this.messageBox.value = true
			}

			if (name === 'default') {
				this.$refs.Dialog.init(item.id)
			}
		},
		deleteListItem (a) {
			if (a.name !== 'DlgYes') {
				this.$set(this.messageBox, 'value', false)
				return
			}

			this.$set(this.messageBox, 'loading', true)

			this.$store.dispatch(this.$options.listStoreName + '/deleteItem', this.messageBox.item).finally(() => {
				this.$set(this.messageBox, 'loading', false)
				this.$set(this.messageBox, 'value', false)
			})
		},
		createNewItem () {
			this.$refs.Dialog.init()
		},
		DialogData (data) {
			this.$store.commit(this.$options.listStoreName + '/updateItem', data)
		}
	},
	mounted () {
		const store = this.$store

		store.commit('core/appToolbar/resetToolbar')
		store.commit('core/appToolbar/setHeader', this.$i18n.translate('Users'))
		store.commit('core/appToolbar/setFilterBtnShow', true)

		store.dispatch(this.$options.listStoreName + '/load')
	},
	destroy () {
		this.$store.unregisterModule(this.$options.listStoreName)
	},
	created () {
		this.$store.commit(this.$options.listStoreName + '/setLimit', 100)
	},
	beforeCreate () {
		this.$options.listStoreName += this.uuid
		usersStoreInit(this.$store, this.$options.listStoreName)
	},
	components: {
		Dialog, ListFilter
	}
}
</script>
