import API from '@/api/users'

const state = function () {
	return {
		data: [],
		filter: { },
		limit: 20,
		total: 0,
		page: 1,
		loading: false
	}
}
const getters = {
	getUserById (state) {
		return (id) => state.data.filter(i => i.id === id)[0] || {}
	}
}

const mutations = {
	setData (state, data) {
		state.data = data
	},
	setLimit (state, p) {
		state.limit = p
	},
	setTotal (state, p) {
		state.total = p
	},
	setOffset (state, p) {
		state.offset = p
	},
	setFilter (state, f) {
		state.filter = f
	},
	setLoading (state, b) {
		state.loading = b
	},
	updateItem (state, data) {
		let stData = [ ...state.data ]
		let index = stData.findIndex((e) => e.id === data['id'])

		if (index === -1) {
			stData.unshift(data)
		} else {
			stData[index] = data
		}

		state.data = stData
	},
	removeItem (state, data) {
		let index = state.data.findIndex((e) => e.id === data['id'])
		state.data.splice(index, 1)
	}
}

const actions = {
	load (ctx, filter) {
		if (filter) ctx.commit('setFilter', filter)
		ctx.commit('setLoading', true)

		return API.loadList({ ...ctx.state.filter, page: ctx.state.page, limit: ctx.state.limit }).then(({ data }) => {
			ctx.commit('setData', data.data || [])
			ctx.commit('setTotal', data.total)

			return data
		}).catch(({ response: { data } }) => {
			ctx.commit('core/snackbar/show', { text: data.message }, { root: true })

			throw data
		}).finally(() => {
			ctx.commit('setLoading', false)
		})
	},
	deleteItem (ctx, itm) {
		return API.deleteItem(itm).then(() => {
			ctx.commit('removeItem', itm)
		}).catch((error) => {
			ctx.commit('core/snackbar/show', { text: error.message }, { root: true })

			throw error
		})
	},
	setFilter (ctx, filter) {
		ctx.commit('setPage', 1)
		return ctx.dispatch('load', filter)
	},
	setPage (ctx, page) {
		ctx.commit('setPage', page)
		return ctx.dispatch('load')
	}
}

export default function (store, storeName) {
	if (store.state[storeName]) return true

	store.registerModule(storeName, {
		namespaced: true,
		state,
		getters,
		actions,
		mutations
	})

	return true
}
