export class UserModuleAction {
	constructor ({ module_action_id = null, config = {} } = {}) {
		this.module_action_id = module_action_id
		this.config = config
	}
}

export function createUserModuleAction (data) {
	return new UserModuleAction(data)
}
