import axios from 'axios'

export default {
	loadItem (id, opt = {}) {
		return axios.get(`/api/users/${id}`, opt)
	},
	loadList (params = {}) {
		return axios.get(`/api/users`, { params })
	},
	saveItem (data) {
		return axios.post('/api/users', data)
	},
	deleteItem (data) {
		return axios.delete(`/api/users/${data.id}`)
	}
}
