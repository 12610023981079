export class User {
	constructor ({ id = null, role_id = null, depot_id = null, first_name = '', last_name = '', lori_id = '', username = '', password = '', mobile_pin = null, secret_key = null, status_id = 100 } = {}) {
		this.id = id
		this.role_id = role_id
		this.depot_id = depot_id
		this.first_name = first_name
		this.last_name = last_name
		this.lori_id = lori_id
		this.username = username
		this.password = password
		this.mobile_pin = mobile_pin
		this.secret_key = secret_key
		this.status_id = status_id
	}
}

export function createUser (data) {
	return new User(data)
}
